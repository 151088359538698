import { appConfig } from '@/config/appConfig';
import { createHttp } from '@/api/HttpHelper';
import { createMJHttp } from '@/api/MJRequest';

const axios = createHttp(appConfig.base2GUrl);
const MJAxios = createMJHttp(appConfig.MJBaseUrl);
const statisticsAxios = createHttp(appConfig.base2GUrl2);
const YWAxios = createHttp(appConfig.YWBaseUrl);

import store from "@/store";
const post = (url, data, options) => axios.post(url, data, {
   headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" },
   ...options
});

//项目列表数据 
export const pageProjectList = (data) => {
   return YWAxios.axios({
      url: '/basic-data/project/pageProjectList',
      method: 'POST',
      loading: true,
      data
   });
};

//导出项目列表数据
export const listApplyDownload = (data) => {
   return YWAxios.axios({
      url: '/basic-data/project/listApplyDownload',
      method: 'POST',
      responseType: "blob",
      timeout: 1800000,
      loading: true,
      data
   });
};

//添加项目数据
export const addProject = (data) => {
   return YWAxios.axios({
      url: '/basic-data/project/addProject',
      method: 'POST',
      loading: true,
      data
   });
};

//项目详情数据
export const getProjectDetail = (data) => {
   return YWAxios.axios({
      url: '/basic-data/project/getProjectDetail',
      method: 'POST',
      loading: true,
      data
   });
};

//删除项目数据
export const removeBatchById = (params) => {
  return YWAxios.axios({
     url: '/basic-data/project/removeBatchById',
     method: 'GET',
     loading: true,
     params
  });
};

//导出项目详情机手数据
export const jsDataDownload = (data) => {
   return YWAxios.axios({
      url: '/basic-data/project/jsDataDownload',
      method: 'POST',
      responseType: "blob",
      timeout: 1800000,
      loading: true,
      data
   });
};

//导出项目详情硬件数据
export const projectDeviceDownload = (data) => { 
   return YWAxios.axios({
      url: '/basic-data/project/projectDeviceDownload',
      method: 'POST',
      responseType: "blob",
      timeout: 1800000,
      loading: true,
      data
   });
};

//导出项目详情风控数据
export const riskDataDownload = (data) => {
   return YWAxios.axios({
      url: '/basic-data/project/riskDataDownload',
      method: 'POST',
      responseType: "blob",
      timeout: 1800000,
      loading: true,
      data
   });
};

//获取风控管理项目筛选条件
export const getRiskTree = (params) => {
  return YWAxios.axios({
     url: '/basic-risk/risk/projectCondition',
     method: 'GET',
     params
  });
};

// 获取绑定项目列表
export function getBindProjectList(data) {
   return YWAxios.axios({
      url: '/basic-data/project/pageProjectList',
      method: 'POST',
      //TODO 后期去掉bladeUserId
      data: { ...data, bladeUserId: store.state.userInfo.MJUserId },
      loading: true,
   });
}
//绑定项目
export function bindUserProject(data) {
   return YWAxios.axios({
      url: '/basic-data/project/userBindProject',
      method: 'POST',
      //TODO 后期去掉bladeUserId
      data: { ...data, bladeUserId: store.state.userInfo.MJUserId }
   });
}

//获取绑定子项目保单列表
export const getProjectBindPolicy = (data) => {
   return YWAxios.axios({
      url: '/basic-data/project/getProjectBindPolicy',
      method: 'POST',
      loading: true,
      data
   });
};



//导出项目详情
export const projectDetailExport = (data) => {
   return YWAxios.axios({
      url: '/basic-data/project/projectDetailExport',
      method: 'POST',
      responseType: "blob",
      timeout: 1800000,
      loading: true,
      data
   });
};

//绑定子项目保单列表
export const bindPolicyProject = (data) => {
   return YWAxios.axios({
      url: '/basic-data/project/bindPolicyProject',
      method: 'POST',
      loading: true,
      data
   });
};
